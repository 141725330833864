// Initialize necessaries behaviours
document.addEventListener('turbolinks:load', () => {
  // On change customer select on user form
  $('#user_customer_id').on('change', function () {
    var customerId = $(this).val();
    var fieldsetUserLevels = $('fieldset.user_levels');

    if (customerId) {
      $.ajax({
        method: 'PUT',
        url: '/users/update_levels',
        data: { customer_id: customerId },
        dataType: 'json',
      }).done(function (result) {
        fieldsetUserLevels.find('div.form-check').remove();

        $.each(result.levels, function (index, level) {
          fieldsetUserLevels.append(
            `<div class="form-check"><input class="form-check-input check_boxes required" required="required" aria-required="true" type="checkbox" value="${level.id}" name="user[level_ids][]" id="user_level_ids_${level.id}"><label class="form-check-label collection_check_boxes" for="user_level_ids_${level.id}">${level.name}</label></div>`
          );
        });
        
        // Reattach event listeners after adding new checkboxes
        attachLevelCheckboxListeners();
      });
    } else {
      fieldsetUserLevels.find('div.form-check').remove();
    }
  });

  // Used on filter_form
  $('#user_customer_id_eq').on('change', function() {
    var customerId = $(this).val();

    if (customerId) {
      window.filter_form.showLevels(customerId, false);
    }
  });

  function attachLevelCheckboxListeners() {
    const clubUserFields = document.querySelector('.club-user-fields');
    const levelCheckboxes = document.querySelectorAll('input[name="user[level_ids][]"]');
    
    async function toggleClubUserFields() {
      try {
        const checkedLevels = Array.from(levelCheckboxes)
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.value);

        // If there are no levels selected, hide the fields
        if (checkedLevels.length === 0) {
          if (clubUserFields) {
            clubUserFields.style.display = 'none';
          }
          return;
        }

        // Check each selected level
        const hasClubUserRole = await Promise.all(
          checkedLevels.map(async levelId => {
            try {
              const response = await fetch(`/users/check_level_role?level_id=${levelId}`);
              const data = await response.json();
              return data.has_club_user_role;
            } catch (error) {
              console.error('Error checking level role:', error);
              return false;
            }
          })
        );

        // If any of the levels has the club_user role, show the fields
        if (clubUserFields) {
          clubUserFields.style.display = hasClubUserRole.includes(true) ? 'block' : 'none';
        }
      } catch (error) {
        console.error('Error checking roles:', error);
        if (clubUserFields) {
          clubUserFields.style.display = 'none';
        }
      }
    }

    // Remove existing event listeners
    levelCheckboxes.forEach(checkbox => {
      checkbox.removeEventListener('change', toggleClubUserFields);
      checkbox.addEventListener('change', toggleClubUserFields);
    });

    // Execute on initial load
    toggleClubUserFields();
  }

  // Initial attachment of event listeners
  attachLevelCheckboxListeners();
});
